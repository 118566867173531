import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from '../styles.module.css';
import MessageItem from './MessageItem';
import { MessengerState } from '../../../../redux/messenger/types';
import { GetState, getCurrenLang, getMessengerState } from '../../../../redux/selector';
import { Message } from '../../../../pages/Messenger/types';

const MessageList = (): ReactElement => {
  const t: any = useSelector<any>((state) => getCurrenLang(state));
  const disclaimer_t = t?.chat?.disclaimer || 'Disclaimer';
  const disclaimer_descr =
    t?.chat?.disclaimer_descr ||
    'By utilising our direct messaging system for healthcare professionals, you acknowledge that your communications may be incorporated into your medical records. Please only share information you are comfortable having documented in your medical files. If you have any concerns, contact our support team.';
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));

  const { messages } = messengerState;
  const bottomRef: any = useRef();
  const [isEditMode, setEditMode] = useState(false);
  useEffect(() => {
    if (!isEditMode) {
      bottomRef.current.scrollIntoView({
        // behavior: 'smooth',
        behavior: 'instant',
        block: 'end',
      });
    }
    setEditMode(false);
  }, [messages]);
  // useEffect(() => {
  //   if (log) {
  //     logRef.current.style.opacity = 0.8;
  //     logRef.current.style.zIndex = 1;
  //     const timerId = setTimeout(() => {
  //       if (logRef.current) {
  //         logRef.current.style.opacity = 0;
  //         logRef.current.style.zIndex = -1;
  //       }
  //       clearTimeout(timerId);
  //     }, 1500);
  //   }
  // }, [log]);

  console.log('777_messages=', messages);

  return (
    <ul className={styles.msgList__container}>
      {messages.map((message: Message, i: number) => {
        const previousAuthorIsSame = i !== 0 ? messages[i - 1].hcpId === message.hcpId : false;
        return (
          <MessageItem
            key={`message${message.id}${message.updatedAt}${String(i)}`}
            message={message}
            setEditMode={setEditMode}
            previousAuthorIsSame={previousAuthorIsSame}
          />
        );
      })}
      {messages?.length === 0 && (
        <div className={styles['msgList__disclaimer-container']}>
          <span className={styles.msgList__disclaimer}>{disclaimer_t}:</span>{' '}
          <span>{disclaimer_descr}</span>
        </div>
      )}
      <p ref={bottomRef} />
      {/* <p ref={logRef} className="log">
        {log}
      </p> */}
    </ul>
  );
};
export default MessageList;
