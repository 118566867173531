import React, { ReactElement, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'antd';

import styles from '../styles.module.css';
import { GetState, getCurrenLang, getMessengerState } from '../../../../redux/selector';
import { Modal } from '../../../../common';
import { MessengerState } from '../../../../redux/messenger/types';
import { ListHcpType, RoomMemberType, RoomType } from '../../../../pages/Messenger/types';
import useChat from '../../../../hooks/useChat';
import { clearAddMembersState, setAddNewMemberOpen } from '../../../../redux/messenger';
import { Button, CloseBtn } from '../../../../common/Button/Button';
import SearchClient from '../../../Search/SearchClient';
import { CustomCheckbox } from '../../../../common/Input';
import { spinner } from '../../../../common/Loader';
import { WarnNotifModal } from '../../../../common/NotificationModal';
import { useSocket } from '../../../../ SocketContext';

const AddMembers = ({
  search,
  changeSearch,
  list,
  listLoading,
  current,
  pageSize,
  total,
  onChangePagination,
}: any): ReactElement => {
  const t: any = useSelector((state: GetState) => getCurrenLang(state));
  const ok = t?.notifications.ok;
  const cancel = t?.common.cancel;
  const select = t?.common.select;
  const not_found = t?.modal.not_found;
  const skip_for_now = t?.chat?.skip_for_now || 'Skip for now';
  const add_members = t?.chat?.add_members || 'Add Members';
  const search_hcp = t?.chat?.search_hcp || 'Search Healthcare Professionals';
  const are_you_sure_leave_channel =
    t?.chat?.are_you_sure_leave_channel || 'Are you sure you want to leave this channel?';
  const are_you_sure_close_conversation =
    t?.chat?.are_you_sure_close_conversation || 'Are you sure you want to close conversation?';
  const channel_members = t?.chat?.channel_members || 'Channel Members';
  const close_without_adding =
    t?.chat?.close_without_adding || 'Close without adding anyone to this channel?';

  const { socket }: any = useSocket();
  const { addMembersToRoom } = useChat(socket);
  const messengerState: MessengerState = useSelector((state: GetState) => getMessengerState(state));
  const { activeRoomId, rooms, roomMembers, isAddNewMemberOpen } = messengerState;

  // Local state
  const [isShowWarning, setWarning] = useState(false);
  const [checkedHcpId, setCheckedHcpId] = useState<number[]>([]);
  const isNoChecked = checkedHcpId.length === 0;
  const dispatch = useDispatch();

  const addMembers = (): void => {
    if (isNoChecked || !activeRoomId) {
      return;
    }
    const request = {
      roomId: activeRoomId,
      hcpIds: checkedHcpId,
    };
    addMembersToRoom(request);
  };

  // Check already added HCPs to members
  const selectedHcpId = useMemo((): number[] => {
    if (roomMembers?.length > 0) {
      return roomMembers.reduce((accum: number[], item: any) => [...accum, item.hcpId], []);
    }
    return [];
  }, [roomMembers]);

  // Selecting user to connect
  const onUpdateCheckbox = (e: any, candidat: ListHcpType): void => {
    const { checked } = e.target;
    if (checked) {
      setCheckedHcpId([...checkedHcpId, candidat.id]);
      return;
    }
    const newChecked = checkedHcpId.filter((item: number) => item !== candidat.id);
    setCheckedHcpId(newChecked);
  };

  // Close Add new member modal window
  const _onclose = (): void => {
    dispatch(clearAddMembersState());
    setWarning(false);
  };

  // Show warning befor close
  const showWarning = () => {
    setWarning(true);
  };

  const content = <div className={styles['add-members__content']}>{close_without_adding}</div>;

  return (
    <Modal onClose={showWarning} style={styles.modal}>
      <CloseBtn close={showWarning} />
      <div className={styles['add-members__title']}>{add_members}</div>

      {/* SEARCH */}
      <div className={styles['add-members__search-container']}>
        <SearchClient
          inputId="searchHcpMember"
          name="search"
          placeholder={search_hcp}
          inputValue={search}
          changeFilter={changeSearch}
        />
      </div>

      {/* List */}
      {listLoading && spinner}
      <ul className={styles['add-members__list_container']}>
        {search && !listLoading && list.length === 0 && (
          <div className={styles.notfound}>
            <div>{not_found}</div>
          </div>
        )}
        {search &&
          !listLoading &&
          list.length > 0 &&
          list.map((item: ListHcpType, i: number) => (
            <li className={styles.listHcp} key={`list${item.id}`}>
              <div className={styles.listHcp__checkbox}>
                <CustomCheckbox
                  htmlId={`member${i}`}
                  name="checkbox"
                  checked={checkedHcpId.includes(item.id) || selectedHcpId.includes(item.id)}
                  onChangeMethod={(e: any): void => onUpdateCheckbox(e, item)}
                  isTouched={false}
                  hasErrors={false}
                  disabled={selectedHcpId.includes(item.id)}
                />
              </div>
              <div className={styles.listHcp_content}>
                <div className={styles.fullName__container}>
                  <div className={styles.hcpName}>{`${item.firstName}, ${item.lastName}`}</div>
                  <div className={styles.hcpProfession}>{item.profession}</div>
                </div>
                <div className={styles.hcpHospitalName}>{item.hospital}</div>
              </div>
            </li>
          ))}
      </ul>
      <div className="wrapper_pagination">
        <Pagination
          size="small"
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={onChangePagination}
        />
      </div>
      <Button
        buttonType="button"
        buttonMethod={addMembers}
        buttonName={select}
        disabledButton={isNoChecked}
        buttonClass={styles.btn}
      />
      <div onClick={showWarning} aria-hidden className={styles.btn__skip}>
        {skip_for_now}
      </div>
      {isShowWarning && (
        <WarnNotifModal
          onClose={(): void => setWarning(false)}
          content={content}
          cancelBtnName={cancel}
          actionBtnName={ok}
          actionMethod={_onclose}
        />
      )}
    </Modal>
  );
};

export default AddMembers;
