import React, { ReactElement } from 'react';
import { Pagination } from 'antd';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import styles from './styles.module.css';
import { Button, Modal } from '../../../common';
import { spinner } from '../../../common/Loader';
import useChat from '../../../hooks/useChat';
import { CHANNEL_TYPE } from '../../../utils/enums';
import { CloseBtn } from '../../../common/Button/Button';
import SearchClient from '../../Search/SearchClient';
import { CustomCheckbox } from '../../../common/Input';
import { ListPatientType } from '../../../pages/Messenger/types';
import { MessagesIcon } from '../../../theme/icons';
import { setTypeChannelAddMembers } from '../../../redux/messenger';
import { useSocket } from '../../../ SocketContext';

const CreateChannel = ({
  t,
  _oncloseAddNewChannelModal,
  search,
  changeSearch,
  list,
  listLoading,
  current,
  pageSize,
  total,
  onChangePagination,
  type,
  activeRoomId,
}: any): ReactElement => {
  const select = t?.common.select;
  const not_found = t?.modal.not_found;
  const search_patient = t?.invite_patient.search_patient;
  const create_channel = t?.chat?.create_channel || 'Create a channel';
  const skip_for_now = t?.chat?.skip_for_now || 'Skip for now';
  const in_channel = t?.chat?.in_channel || 'In the channel';
  const channels_serve =
    t?.chat?.channels_serve ||
    "Channels serve as dedicated spaces for discussions related to individual patients, with each channel named after the respective patient's name and email address.";
  const only_one_channel =
    t?.chat?.only_one_channel ||
    'Only one channel can be created per patient, and a patient cannot be included in more than one channel.';

  const { socket }: any = useSocket();
  const { createRoom } = useChat(socket);
  const dispatch = useDispatch();

  const initialValues: any = {
    channelName: '',
    checkedCandidat: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (requestData: any) => {
      let channelName = `${requestData.checkedCandidat.firstName} ${requestData.checkedCandidat.lastName}`;
      if (type === CHANNEL_TYPE.GROUP) {
        channelName = `${channelName} | ${requestData.checkedCandidat.email}`;
        dispatch(setTypeChannelAddMembers(CHANNEL_TYPE.GROUP));
      }
      const request = {
        userId: requestData.checkedCandidat.id,
        name: channelName,
        type,
      };
      createRoom(request, activeRoomId);
    },
  });
  const { values } = formik;

  // Selecting user to connect
  const onUpdateCheckbox = (e: any, candidat: any): void => {
    const { checked } = e.target;
    if (checked) {
      formik.setFieldValue('checkedCandidat', candidat);
      return;
    }
    formik.setFieldValue('checkedCandidat', null);
  };

  return (
    <Modal onClose={_oncloseAddNewChannelModal} style={styles.modal}>
      {/* Header */}
      <div className={styles['msg__modal-header']}>
        <CloseBtn close={_oncloseAddNewChannelModal} />
        <div className={styles['msg__modal-title']}>{create_channel}</div>
        <div className={styles['msg__modal-description']}>{channels_serve}</div>
        <div className={styles['msg__modal-description']}>{only_one_channel}</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        {/* SEARCH */}
        <div className={styles['msg__modal-subtitle']}>{search_patient}</div>
        <SearchClient
          inputId="searchMember"
          name="search"
          placeholder={search_patient}
          inputValue={search}
          changeFilter={changeSearch}
        />

        {/* List */}
        {listLoading && spinner}
        <ul className={styles['msg__modal-list_container']}>
          {search && !listLoading && list.length === 0 && (
            <div className={styles.notfound}>
              <div>{not_found}</div>
            </div>
          )}
          {search &&
            !listLoading &&
            list.length > 0 &&
            list.map((item: ListPatientType, i: number) => (
              <li className={styles.listHcp} key={`list${item.id}`}>
                <div className={styles.listHcp__checkbox}>
                  <CustomCheckbox
                    htmlId={`member${i}`}
                    name="checkbox"
                    checked={values.checkedCandidat?.id === item.id}
                    onChangeMethod={(e: any): void => onUpdateCheckbox(e, item)}
                    isTouched={false}
                    hasErrors={false}
                    disabled={item.isChannelExist}
                  />
                </div>
                <div className={styles.listHcp_content}>
                  <div className={styles.fullName__container}>
                    <div className={styles.hcpName}>{`${item.firstName}, ${item.lastName}`}</div>
                    <div className={styles.hcpEmail}>{item.email}</div>
                    {item.isChannelExist && (
                      <div className={styles['msg__in-channel']}>
                        <MessagesIcon /> <span>{in_channel}</span>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}
        </ul>
        <div className="wrapper_pagination">
          <Pagination
            size="small"
            current={current}
            pageSize={pageSize}
            total={total}
            onChange={onChangePagination}
          />
        </div>
        <Button
          buttonType="submit"
          buttonName={select}
          disabledButton={!formik.dirty || !values.checkedCandidat}
          buttonClass={styles.btn}
        />
        <div onClick={_oncloseAddNewChannelModal} aria-hidden className={styles.btn__skip}>
          {skip_for_now}
        </div>
      </form>
    </Modal>
  );
};

export default CreateChannel;
